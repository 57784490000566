.service{
    margin: 40px 0;
}
.service img{
    display: block;
    width: 100%;
}
.service h1{
    color: #333333;
    border-left: .3rem solid #1d4ed8;
    padding-left: .4rem;
}
.service .hero-wrap .link-reviews i{
    display: inline-block;
    margin-left: .5em;
}
.service .link-reviews{
    padding: 1rem 1.5rem;
    text-decoration: none;
    width: fit-content;
    display: block;
    margin-top: 1rem;
    color: #000000;
    transition: all 0.3s ease 0s;
    border: 1px solid ;
    width: 100%;
    text-align: center;
}
.service .link-reviews:hover{
    background-color: #000000;
    color: white;
}
@media screen and (min-width: 1023px) {
    .service .link-reviews{
        width: fit-content;
        margin-left: auto;
    }
}
