@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
/* @import 'node_modules/scroll-carousel/dist/scroll.carousel.min.css'; */
.img-scroll {
    background-image: url('../img/takayai.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll2 {
    background-image: url('../img/Blue_Lagoon3.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll3 {
    background-image: url('../img/Chang_Arena_Buriram3.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll4 {
    background-image: url('../img/Khao_Kho3.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll5 {
    background-image: url('../img/Phamanfun4.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll6 {
    background-image: url('../img/Phu_Thap_Buek4.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll7 {
    background-image: url('../img/Wat_Pha_Son_Kaew4.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-scroll8 {
    background-image: url('../img/Wat_Phra_Yai3.jpg');
    background-size: cover;
    background-position: center 60%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    border: 10px solid white;
    border-radius: 20px;
    /* margin-right: 20px !important; */
    z-index: -10;
    /* Set .imgBox to have relative positioning */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.con {
    position: relative;
    text-align: center;
    color: white;

}
.test{
    text-decoration: none;
}
.centered {
    position: absolute;
    top: 42%;
    left: 10%;
    
}
.hei{
    height: 80vh;
    width: 100%;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgb(0, 0, 0,0.3) 100%), url('../img/view_hero.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    z-index: -10;
    /* opacity: 0.5; */
}
.d-nn{
    display: block;
    
}
@media screen and (max-width:767px) {
    .hei{
        height: 40vh;
        width: 100%;
        object-fit: cover;
    }
    .centered {
        position: absolute;
        top: 70%;
        left: 10%;
        /* transform: translate(-50%, -50%); */
        
    }
    .d-nn{
        display: block;
    }
    .font-si{
        font-size: 1.4em;
    }
    .font-sl{
        font-size: 9px;
    }
    .text-cen{
        text-align: center !important;
    }
}

@media screen and (min-width:351px) {

    .centered {
        position: absolute;
        top: 45%;
        left: 9%;
        /* transform: translate(-50%, -50%); */      
    }
}

@media screen and (max-width:389px) {

    .centered {
        position: absolute;
        top: 70%;
        left: 9%;
        /* transform: translate(-50%, -50%); */      
    }
    .scroll-carousel-slider {
        height: 30vh !important;
    }
}



.bg-transparent {
    color: transparent !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.box-image {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.scroll-carousel-viewport {
    overflow: hidden;
    position: relative;
    width: 100%
}

.scroll-carousel-slider {
    display: inline-flex;
    position: relative;
    height: 35vh;
}

.sc-slide {
    display: inline-block;
    position: relative
}

.sc-slide img,
.sc-slide video {
    max-width: fit-content !important
}