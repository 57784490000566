/* logo font */
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@700&display=swap');
/* content font */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

header {
    font-family: 'Lato', sans-serif;
    display: flex;
    height: auto;
    background-color: #ffffff;
    ;
    justify-content: center;
    padding: 0 30px;
    position: relative;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

}

.nav-toggle {
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 7px;
    font-size: 30px;
    display: inline-block;
    transform: translate(-50%, -50%);
}

.nav-toggle i {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 1.2em;
}

header .logo {
    margin: 30px 0;
}

header a {
    text-decoration: none;
    color: #333333;
}

nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}

nav ul li a {
    display: block;
    padding: 5px 58px;
}

nav ul li {
    list-style-type: none;
    font-size: 15px;
    cursor: pointer;
    margin: 20px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-bottom-width: 1px;
    /* padding: 5px 58px; */
    transition: all 0.3s ease 0s;
}

nav ul li a button {
    padding: 10px 20px;
    background-color: #FA8443;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-size: 1em;

}

nav ul li a button:hover {
    background-color: #d87139;
}

nav ul li:first-child {
    margin-top: 0;
}

nav ul li:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

nav ul li:hover {
    border-bottom-color: #1e40af !important;
}

nav ul li:hover a {
    color: #1e40af !important;
}

.link-active {
    color: #1e40af !important;
    position: relative;
    border-bottom: 1px solid #1e40af !important;
}

.active {
    display: flex;
}

.logo a {
    font-size: .8em;
    font-family: 'Reem Kufi', sans-serif;
    color: #3b82f6;
    word-spacing: .4em;
}

.cta {
    display: none;
}

@media screen and (max-width: 390px) {
    .logo a {
        font-size: .55em;
    }

    nav ul li:last-child {
        display: none;
    }
}

@media screen and (min-width: 768px) {

    header {
        flex-direction: row;
        align-items: center;
    }

    .logo {
        margin: 20px !important;
        flex: 1;
        cursor: pointer;
        user-select: none;
    }

    .logo a {
        font-size: .5em;
    }

    nav ul {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
    }

    nav ul li {
        display: inline-block;
        margin: 0 70px 0 0;
        text-align: left;
        border-bottom: 0;
    }

    nav ul li a {
        padding: 0;
    }

    nav ul li:last-child {
        margin: 0;
    }

    .nav-toggle {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .cta {
        display: block;
    }
}