/* content font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
/* icon */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.slide-up {
    opacity: 0;
    /* Initially hide the content */
    transform: translateY(20px);
    /* Initial position (off screen) */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-up.active {
    opacity: 1;
    /* Make the content visible */
    transform: translateY(0);
    /* Slide in from the top */
}

.hero {
    display: grid;
    grid-template-columns: 1fr;
    margin: 20px 0;
    /* background-color: black; */
    z-index: 1;
}

.slogan {
    padding: 0 10px;
    text-align: center;
}

.slogan h1 {
    width: 100%;
    color: #333333;
    margin-bottom: 1em;

}

.slogan p {
    margin: 0;
    padding: 0;
}

.hero-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    z-index: -2;
}

.hero-img-marker .people {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
    z-index: 200;
}

.train {
    display: flex;
    background-color: white;
    padding: 5px;
    box-shadow: rgba(120, 126, 133, 0.2) 0px 8px 24px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease 0s;
    color: #42a7c3;
    position: absolute;
    top: 10%;
    left: 16%;
}

.plane {
    display: flex;
    background-color: white;
    padding: 5px 10px;
    box-shadow: rgba(120, 126, 133, 0.2) 0px 8px 24px;
    width: fit-content;
    height: fit-content;
    border-radius: 25px;
    transition: all 0.3s ease 0s;
    color: #42a7c3;
    font-size: .85em;
    position: absolute;
    top: 20%;
    right: 5%;
}

.plane span {
    font-family: 'Reem Kufi', sans-serif;
    margin-left: 5px;
}

.banner:nth-of-type(1) {
    top: 60%;
    left: 6%;
}

.banner:nth-of-type(2) {
    top: 42%;
    right: 5%;
}

.banner {
    background-color: white;
    width: fit-content;
    padding: 10px;
    border-radius: 16px;
    height: fit-content;
    box-shadow: rgba(120, 126, 133, 0.2) 0px 8px 24px;
    transition: all 0.3s ease 0s;
    position: absolute;
}

.banner img {
    display: block;
    width: 60px;
    border-radius: 8px;
    margin-bottom: .5em;
}

.banner .title {
    width: 60px;
    font-weight: bold;
    font-size: 5px;
    margin-bottom: 1em;
}

.banner .location {
    width: 60px;
    font-size: 5px;
    margin: 0;
}

.hero-img .banner:hover,
.hero-img .train:hover,
.hero-img .plane:hover {
    transform: scale(1.15);
    user-select: none;
}

@media screen and (min-width: 768px) {
    .train {
        transform: scale(2);
        top: 10%;
        left: 28%;
    }

    .plane {
        transform: scale(2);
        top: 20%;
        right: 18%;
    }

    .hero-img .banner {
        transform: scale(2);
    }

    .banner:nth-of-type(1) {
        top: 65%;
        left: 20%;
    }

    .banner:nth-of-type(2) {
        top: 45%;
        right: 20%;
    }

    .hero-img .banner:hover,
    .hero-img .train:hover,
    .hero-img .plane:hover {
        transform: scale(2.5);
        user-select: none;
    }
}

@media screen and (min-width: 1023px) {
    .hero {
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
    }

    .train {
        transform: scale(1.8);
        top: 9%;
        left: 29%;
    }

    .plane {
        transform: scale(1.8);
        top: 18%;
        right: 18%;
    }

    .hero-img .banner {
        transform: scale(2);
    }

    .banner .title {
        font-size: .4em;
    }

    .banner:nth-of-type(1) {
        top: 65%;
        left: 16%;
    }

    .banner:nth-of-type(2) {
        top: 45%;
        right: 15%;
    }

    .hero-img .banner:hover {
        transform: scale(2.5);
        user-select: none;
    }

    .hero-img .train:hover,
    .hero-img .plane:hover {
        transform: scale(1.95);
        user-select: none;
    }
}