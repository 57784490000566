.car-gallery {
    height: fit-content;
}

.car-gallery-row {
    display: flex;
    flex-direction: column;
}

.full-width {
    flex: 1 !important;
}
.modal.show .modal-dialog {
    /* width: 100%; */
}
.badge{
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.795) !important;
    color: rgb(0, 0, 0) !important;
    top: 10px;
    right: 10px;
    /* padding: .5rem 1rem; */
    font-size: .7rem;
    font-weight: bold;
}
.car-gallery .car-gallery-item {
    border: 1px solid rgba(255, 255, 255, 0);
    width: 100%;
    flex: 0 0 calc(50%);
    position: relative;
    overflow: hidden;
}

.car-gallery .car-gallery-item img {
    display: block;
    object-fit: cover;
    height: 30vh;
    background-position: 50% 50%;
    transition: transform 0.2s ease;
    cursor: pointer;
    transition: all 0.5s ease 0s;
}

.car-gallery .car-gallery-item img:hover {
    opacity: .9;
    transform: scale(1.02);
}
.destination-desc{
    font-size: 1.25rem;
    padding: 20px;
    width: 100%;
    margin: 0;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, #000000d4, #00000085, #31313175, #5050505c, #f8f8f800);
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    border-radius: 5px !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 0 !important;
    text-align: center;
    position: relative;
    width: 100%;
}
.modal-header {
    padding: 0 !important;
    background-color: transparent;
    position: absolute;
    top: 4%;
    right: 5%;
    z-index: 30;
    border-radius: 0;
    border: 0;
}
.modal-body {
    padding: 0 !important;
    width: 100%;
}
.modal {
    padding: 0 !important;
    --bs-modal-width: 70%;
    width: 100%;
}
.modal-backdrop.show {
    opacity: .9 !important;
}

.modal-content img {
    width: 100%;
    height: 80vh;
    object-fit: contain;
    background-color: black;
}
.modal-header .btn-close {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: rgb(255, 255, 255)000;
    border: none;
    border-radius: 50%;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}
.clearBtn{
    padding: 0 !important;
    text-decoration: none !important;
    display: block !important;
    margin-top: 0 !important;
    color: #00000000 !important;
    border: 0 !important;
    width: 100% !important;
}

.close-button:hover {
    background-color: #555;
}
@media screen and (min-width: 760px) {
    .car-gallery-row {
        flex-direction: row;
    }
    .car-gallery .car-gallery-item img {
        height: 40vh;
    }
}
@media screen and (min-width: 1023px) {
    .car-gallery .car-gallery-item img {
        height: 40vh;
    }
    .destination-desc{
        /* font-size: 1.5rem; */
    }
    .car-gallery-row {
        flex-direction: row;
    }
}