footer {
    padding: 60px 30px 30px;
    color: rgb(0, 0, 0);
    background-color: #18181b;
}
footer h1{
    font-size: 1.2em;
    /* font-weight: normal; */
    color: #3b82f6;
    margin-bottom: 1em;
}
footer ul{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
footer ul li {
    padding: 1em 0;
    
    
}
footer ul li a{
    text-decoration: none;
    color: #afafaf;
}
footer .copyright{
    margin-top: 1em;
    color: #afafaf;
    text-align: center;
}
footer .bi-facebook{
    margin-right: .85em;
}
footer .bi-facebook::before{
    scale: 1.5;


}
footer hr{
    color: #afafaf;
}
@media screen and (min-width: 1023px) {
    footer ul{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    footer ul li {
        margin-right: 6em;
    }
    footer h1{
        text-align: center;
        margin-bottom: 2rem;
    }
}
