* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}

.main {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: fit-content;
    width: 100%;
}

.main .vector {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 15%;
    left: 0;
    opacity: 100%;
}

.content {
    padding: 0 30px;
}

.hero-wrap {
    margin-bottom: 1rem;
}

ul {
    margin: 0 !important;
    padding: 0 !important;
}

p {
    margin: 0 !important;
}
.content .content-wrap{
    display: grid;
    grid-template-columns: 1fr;
}
.bi.bi-telephone-fill::before{
    font-size: .9em;
}
table.MuiTable-root{
    box-shadow: none;
}
.MuiTableHead-root tr th{
    background-color: #3b82f6 !important;
    color: white;
}
div.MuiPaper-root{
    box-shadow: none;
    border: 1px solid #ececec;
}
@media screen and (min-width: 760px) {
    .content .content-wrap{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .content .content-wrap .hero-destination,
    .content .content-wrap .service,
    .content .content-wrap .service-list
    {
        width: 100%;
    }
    .content {
        padding: 0 60px;
    }
}

@media screen and (min-width: 1023px) {
    .content {
        padding: 0 100px;
    }
    /* .content .content-wrap {
        margin: 0 auto;
        display: grid;
        column-gap: 5em;
        grid-template-columns: repeat(1, 1fr);
    } */
    .content .content-wrap .hero-destination{
        height: 450px;
        height: auto;
    }
    .hero-destination h1,
    .service h1,
    .service-list h1 {
        font-size: 1.5em;
    }
    .service h1 {
        color: #333333;
    }
    .service-list .detail-list {
        padding: 20px 40px 0 !important;
    }
}