.service-list {
    margin: 40px 0;
}
.service-list h1{
    color: #333333;
    border-left: .3rem solid #1d4ed8;
    padding-left: .4rem;
}
.service-wrap{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}

.service-list ul{
    list-style-type: none;
    margin: 0 auto 1.5rem !important;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}
.service-list ul li{
    border: 1px solid #dadada;
    border-radius: 50px;
}
.service-list ul li svg{
    width: 30px;
    height: 30px;
}

.service-list ul li div.circle{
    padding: 8px;
    border-radius: 50%;
    background-color: #2563eb;
    left: -11%;
}

.service-list .detail-list:last-child{
    margin-bottom: 0 !important;
}
.service-list .detail-list h2{
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}
.service-price{
    display: inline-block;
    margin-left: auto;
    width: 100px;
    text-align: end;
}
.service-list .detail-list li{
    margin-bottom: 1rem;
    border-bottom: 1px solid #dadada;
    padding-bottom: 1rem;
    display: flex;
}

.service-list .detail-list li:first-of-type{
    margin-top: 1rem;
}
.service-list .detail-list li:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}
.service-list .detail-list li i{
    margin-right: 1em;
    display: inline-block;
    color: #42a7c3;
}
.service-list .detail-list li span{
    display: inline-block;
}
.service-list .hero-wrap .link-reviews i{
    display: inline-block;
    margin-left: .5em;
}
.service-list .hero-wrap .link-reviews{
    width: fit-content;
    display: block;
    color: white;
    text-decoration: none;
    padding: .5em 1em;
    border-radius: 30px;
    border: 1px solid #42a7c3;
    background-color: #42a7c3;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
}
.service-list .hero-wrap .link-reviews:hover{
    background-color: #2d869e;
}
.grid-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important; 
}
.grid-2 li:first-child{
    margin-top: 0 !important;
}
/* .grid-2 li:last-child{
    margin-bottom: 1rem !important;
    border-bottom: 1px solid #dadada !important;
    padding-bottom: 1rem !important;
} */

@media screen and (min-width: 760px) {
    .service-wrap{
        grid-template-columns: repeat(1, 1fr);
        gap: 1em;
    }
    .service-list .detail-list{
        width: 100%;
    }
    .service-list .detail-list:first-child{
        margin: 0 2rem 0 0 !important;
    }
    .service-list ul{
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }
}
@media screen and (min-width: 1023px) {
    .service-list ul{
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
    }
    .service-wrap{
        grid-template-columns: repeat(1, 1fr);
        gap: 1em;
    }
    .service-list .detail-list{
        /* margin: 0 1rem 0 0 !important; */
        width: 100%;
        height: 80vh;
    }
}