* {
    box-sizing: border-box;
}

.imgBox2 {
    background-image: url('../img/Line_2.png');
    position: relative;
    height: 90vh;
    width: auto;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;

}

.imgBox3 {
    background-image: url('../img/Line_2.png');
    position: relative;
    height: 50vh;
    width: auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

}

.centeredss {
    position: absolute;
    top: 33%;
    left: 7%;
}

.centeredss2 {
    position: relative;
    left: -7%;

}


.padddd {
    padding-right: 100px;
}

.marconn {
    margin-top: 100px;
    margin-bottom: 100px;
}

@media screen and (max-width:767px) {
    .centeredss {
        position: relative;
        padding-right: 20px !important;
        padding-left: 20px !important;
        top: 0%;
        left: 0%;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .centeredss2 {
        position: relative;
        left: -5%;
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .centeredss3 {
        position: relative;
        left: 0%;
        padding-right: 20px !important;
        padding-left: 20px !important;
        margin-top: 20px;
    }
    .padddd {
        padding-right: 0;
    }
    .marconn h2{
        font-size: 1.5em;
    }
}