section.map{
    padding: 100px 200px;
}
section.map h1{
    text-align: center;
    font-size: 2.5em;
    color: #333333;
    margin-bottom: .2em;
}
section.map p{
    text-align: center;
    margin-bottom: 3em;
    font-size: 1em;
}

.embed-map iframe{
    width: 100%;
    height: 500px;
    border: 0;
}